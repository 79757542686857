import React, { useState, createContext } from "react";
import "./App.css";
import DashboardIndex from "./components/Dashboard/DashboardIndex";
import Login from "./components/login-page/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router } from "react-router-dom";
const LoginContext = createContext();

function App() {
  const [user, setUser] = useState({});

  const [createUser, setCreateUser] = useState(false);

  function getComponent() {
    if (!user.isLoggedIn) {
      return <Login setUser={setUser} />;
    } else {
      return <DashboardIndex />;
    }
  }

  return (
    <>
      <LoginContext.Provider
        value={{ user, setUser, createUser, setCreateUser }}
      >
        <Router>{getComponent()}</Router>
      </LoginContext.Provider>
    </>
  );
}

export { App, LoginContext };
