export const clientHeadings = [
    "S.No",
    "Name",
    "Email",
    "Phone",
    "Address",
    "Created By",
    "Tiktok",
    "Instagram",
    "Settings",
    "Actions",
  ],
  userVideoDetails = [
    "Video ID",
    "Sound ID",
    "Video Url",
    "Views",
    "Likes",
    "Shares",
    "Saved",
    "Comments",
    "Created At",
    "Details",
  ],
  videoDetails = [
    "views",
    "likes",
    "comments",
    "shares",
    "saved",
    "created_at",
    "updated_at",
  ],
  soundDetailsHeadings = [
    "id",
    "User Count",
    "Sound ID",
    "Created At",
    "Updated At",
  ];
