import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from 'react-router-dom';
import DaysFilter from './component/DaysFilter';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend);

const TikTokUserStatistics = () => {
    const { tiktokId } = useParams();
    const [selectedDays, setSelectedDays] = useState(30);
    const [userStatistics, setUserStatistics] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserStatistics = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            try {
                const response = await axiosInstance.post('/api/tiktok/tiktok-statistics', {
                    tiktok_id: tiktokId,
                    days: selectedDays,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserStatistics(response.data.statistics);
                setUserDetails(response.data.user_details);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch TikTok user statistics');
                setLoading(false);
            }
        };

        fetchUserStatistics();
    }, [tiktokId, selectedDays]);

    const generateChartData = () => {
        const labels = userStatistics.map(stat => new Date(stat.recorded_at).toLocaleDateString());
        const data = {
            labels,
            datasets: [
                {
                    label: 'Followers',
                    data: userStatistics.map(stat => stat.follower_count),
                    borderColor: '#36A2EB',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
                {
                    label: 'Following',
                    data: userStatistics.map(stat => stat.following_count),
                    borderColor: '#FFCE56',
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total Favorited',
                    data: userStatistics.map(stat => stat.total_favorited),
                    borderColor: '#4BC0C0',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
                {
                    label: 'Visible Videos Count',
                    data: userStatistics.map(stat => stat.visible_videos_count),
                    borderColor: '#FF6384',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                },
            ],
        };
        return data;
    };

    return (
        <div className="container tiktok-user-statistics-container">
            <CommonHeader
                dashboardName={userDetails.nickname + " - TikTok User Statistics"}
            />
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />
            {loading ? (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            ) : error ? (
                <div className="error-overlay">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="chart-wrapper">
                    {userStatistics.length > 0 ? (
                        <Line
                            data={generateChartData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: { position: 'top' },
                                    title: { display: true, text: 'TikTok User Statistics' },
                                },
                            }}
                        />
                    ) : (
                        <p>No user statistics available.</p>
                    )}
                </div>
            )}
            <div className="mt-5">
                <p className="text-center">TikTok User Statistics</p>
                <table className="user-table">
                    <thead>
                        <tr>
                            <th>Followers</th>
                            <th>Followings</th>
                            <th>Total Likes</th>
                            <th>Total Videos</th>
                            <th>Recorded At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userStatistics.length > 0 ? (
                            userStatistics?.map((user, index) => (
                                <tr className=""
                                    key={user.id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                                    }}>
                                    <td>{user.follower_count}</td>
                                    <td>{user.following_count}</td>
                                    <td>{user.total_favorited}</td>
                                    <td>{user.visible_videos_count}</td>
                                    <td>{new Date(user.recorded_at).toLocaleDateString()}</td>
                                </tr>
                            ))
                        ) : (
                            <p>No user statistics available.</p>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TikTokUserStatistics;
