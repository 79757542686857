import React from "react";
import { IoMdAdd } from "react-icons/io";
import "./commoncomponent.css";
import { hasPermission } from "../../../helperPermissionEditor";

const CommonHeader = ({
  dashboardName,
  searchQuery,
  onSearchChange,
  handleShow,
  user,
}) => {
  const permissionMap = {
    User: "create-user",
    Role: "create-role",
    Freelancer: "create-freelancer",
    Client: "create-client",
    "Account Manager": "create-account-manager",
    Permission: "create-permission",
    Phone: "create-phone",
    Labels: "create-label",
    Sound: "create-sound",
    Book: "create-book",
    Text: "create-text",
    Company: "create-company",
    Hashtag: "create-hashtag",
  };

  const canCreate = permissionMap[dashboardName]
    ? hasPermission(user.permissions, permissionMap[dashboardName])
    : false;

  return (
    <div>
      <div className="Common_container d-flex justify-content-between align-items-center">
        <div className="list d-flex justify-content-between align-items-center">
          <h1 className="bold fs-4 mb-0 me-4">{dashboardName} Listing</h1>

          {canCreate && (
            <p
              style={{ cursor: "pointer" }}
              className="me-4 mb-0 d-flex align-items-center "
              onClick={handleShow}
            >
              <IoMdAdd className="fs-5 me-2" />
              {`Add ${dashboardName}`}
            </p>
          )}
        </div>

        <div className="search_bar">
          <div className="group">
            <svg viewBox="0 0 24 24" aria-hidden="true" className="icon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              className="searchinput"
              type="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonHeader;
