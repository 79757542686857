import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from './DaysFilter';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TikTokFollowerGrowth = () => {
    const [selectedDays, setSelectedDays] = useState(30);
    const [followerGrowthData, setFollowerGrowthData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFollowerGrowth = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");
            try {
                const response = await axiosInstance.post("/api/dashboard/follower-growth",
                    {
                        days: selectedDays,
                        limit: 30
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                setFollowerGrowthData(response.data.data);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch TikTok follower growth data");
                setLoading(false);
            }
        };

        fetchFollowerGrowth();
    }, [selectedDays]);

    const generateChartData = () => {
        const labels = followerGrowthData.map(item => item.date);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Total Followers',
                    data: followerGrowthData.map(item => item.total_followers),
                    borderColor: '#36A2EB',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
            ],
        };
        return data;
    };

    return (
        <div className="follower-growth-container">
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />
            {loading ? (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            ) : (
                <div className="chart-wrapper">
                    {followerGrowthData.length > 0 ? (
                        <Line
                            data={generateChartData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: { position: 'top' },
                                    title: { display: true, text: 'TikTok Follower Growth' },
                                },
                            }}
                        />
                    ) : (
                        <p>No follower growth data available.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default TikTokFollowerGrowth;
