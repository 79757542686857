import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import YearsFilter from './YearsFilter';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const ViewsAvgLineMetrix = () => {
    const [selectedYear, setYear] = useState(2024);
    const [viewsAvgData, setViewsAvgData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchViewsAvg = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");
            try {
                const response = await axiosInstance.post("/api/tiktok/views-average",
                    {
                        year: selectedYear,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                setViewsAvgData(response.data.data);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch views average data");
                setLoading(false);
            }
        };

        fetchViewsAvg();
    }, [selectedYear]);

    const generateChartData = () => {
        const labels = viewsAvgData.map(item => `${item.month}-${item.year}`);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Views Avg',
                    data: viewsAvgData.map(item => item.average_views),
                    borderColor: '#36A2EB',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
            ],
        };
        return data;
    };

    return (
        <div className="views-avg-line-graph">
            <YearsFilter selectedYear={selectedYear} onChange={setYear} />
            {loading ? (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            ) : (
                <div className="chart-wrapper">
                    {viewsAvgData.length > 0 ? (
                        <Line
                            data={generateChartData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: { position: 'top' },
                                    title: { display: true, text: 'Views Average' },
                                },
                                scales: {
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Month-Year',
                                        },
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: 'Average Views',
                                        },
                                        beginAtZero: true,
                                    },
                                },
                            }}
                        />
                    ) : (
                        <p>No data available for the selected year.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewsAvgLineMetrix;
