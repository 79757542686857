import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from 'react-router-dom';
import DaysFilter from './component/DaysFilter';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend);

const TikTokVideoStatistics = () => {
    const { videoId } = useParams();
    const [selectedDays, setSelectedDays] = useState(30);
    const [videoStatistics, setVideoStatistics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideoStatistics = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            try {
                const response = await axiosInstance.post('/api/tiktok/video-statistics', {
                    video_id: videoId,
                    days: selectedDays,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setVideoStatistics(response.data.statistics);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch TikTok video statistics');
                setLoading(false);
            }
        };

        fetchVideoStatistics();
    }, [videoId, selectedDays]);

    const generateChartData = () => {
        const labels = videoStatistics.map(stat => new Date(stat.created_at).toLocaleDateString());
        const data = {
            labels,
            datasets: [
                {
                    label: 'Views',
                    data: videoStatistics.map(stat => stat.views),
                    borderColor: '#36A2EB',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
                {
                    label: 'Likes',
                    data: videoStatistics.map(stat => stat.likes),
                    borderColor: '#FFCE56',
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    fill: true,
                },
                {
                    label: 'Comments',
                    data: videoStatistics.map(stat => stat.comments),
                    borderColor: '#4BC0C0',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
                {
                    label: 'Shares',
                    data: videoStatistics.map(stat => stat.shares),
                    borderColor: '#FF6384',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                },
                {
                    label: 'Saved',
                    data: videoStatistics.map(stat => stat.saved),
                    borderColor: '#FF5733',
                    backgroundColor: 'rgba(255, 87, 51, 0.2)',
                    fill: true,
                },
            ],
        };
        return data;
    };

    return (
        <div className="container video-statistics-container">
            <CommonHeader
                dashboardName={videoId + " - Video Statistics"}
            />
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />
            {loading ? (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            ) : error ? (
                <div className="error-overlay">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="chart-wrapper">
                    {videoStatistics.length > 0 ? (
                        <Line
                            data={generateChartData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: { position: 'top' },
                                    title: { display: true, text: 'TikTok Video Statistics' },
                                },
                            }}
                        />
                    ) : (
                        <p>No video statistics available.</p>
                    )}
                </div>
            )}
            <div className="mt-5">
                <p className="text-center">TikTok Video Statistics</p>
                {videoStatistics.length > 0 ? (
                    <table className="user-table">
                        <thead>
                            <tr>
                                <th>Views</th>
                                <th>Likes</th>
                                <th>Comments</th>
                                <th>Shares</th>
                                <th>Saved</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {videoStatistics.map((stat, index) => (
                                <tr
                                    className=""
                                    key={stat.id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                                    }}
                                >
                                    <td>{stat.views}</td>
                                    <td>{stat.likes}</td>
                                    <td>{stat.comments}</td>
                                    <td>{stat.shares}</td>
                                    <td>{stat.saved}</td>
                                    <td>{new Date(stat.created_at).toLocaleDateString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No video statistics available.</p>
                )}
            </div>
        </div >
    );
};

export default TikTokVideoStatistics;
