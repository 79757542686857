import React, { useEffect, useState, useContext } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";


const TikTokVideosIndex = () => {
    const [videos, setVideos] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 20,
        current_page: 1,
        last_page: 1,
        next_page_url: null,
        prev_page_url: null,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageStart, setPageStart] = useState(1);

    const fetchTikTokVideos = async (page = 1) => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
            const response = await axiosInstance.post(`/api/tiktok/user/videos?page=${page}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setVideos(response.data.data);
            const { pagination: apiPagination } = response.data;
            setPagination(apiPagination);
            setCurrentPage(apiPagination.current_page);
            setTotalPages(apiPagination.last_page);
            setLoading(false);
        } catch (err) {
            setError("Failed to fetch TikTok Videos");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTikTokVideos(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
        const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
        setPageStart(newPageStart);
    };

    return (
        <div className="clientLists userLists">
            <CommonHeader dashboardName={"TikTok Videos"} />
            {isLoading && (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            )}
            <div className="mt-3">
                <div className="userFetchData">
                    <div className="userDetails">
                        <div className="details mt-4">
                            <table className="user-table">
                                <thead>
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Tiktok Username</th>
                                        <th>Video ID</th>
                                        <th>Video Link</th>
                                        <th>Video Creation Time</th>
                                        <th>Views</th>
                                        <th>Likes</th>
                                        <th>Comments</th>
                                        <th>Shares</th>
                                        <th>Saved</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {videos.length > 0 ? (
                                        videos.map((video, index) => (
                                            <tr key={index}>
                                                <td>{video.client}</td>
                                                <td>{video.tiktok_nickname}</td>
                                                <td>
                                                    <Link to={`/Tiktok-video-statistics/${video?.video_id}`}>
                                                        {video.video_id}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <a href={video.video_url} target="_blank" rel="noopener noreferrer">
                                                        View Video
                                                    </a>
                                                </td>
                                                <td>{video.create_time}</td>
                                                <td>{video.views}</td>
                                                <td>{video.likes}</td>
                                                <td>{video.comments}</td>
                                                <td>{video.shares}</td>
                                                <td>{video.saved}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10">No videos found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {totalPages > 1 && (
                            <div className="pagination">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                    pageStart={pageStart}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TikTokVideosIndex;