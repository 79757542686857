import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./pages.css";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../../loader/CommonLoader";
import { Toaster, toast } from "sonner";
import CommonHeader from "../common-components/CommonHeader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { TbEdit } from "react-icons/tb";
import { TbEditOff } from "react-icons/tb";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import CustomModal from "../common-components/customModal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import DeleteModal from "../common-components/deleteModal";
import { FiEye } from "react-icons/fi";
import { BsEyeSlash } from "react-icons/bs";

const RolesListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [roles, setRoles] = useState([]),
    [isEditing, setIsEditing] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [isCreatingRole, setIsCreatingRole] = useState(false), // State variable to manage the visibility of the create role form
    [newRole, setNewRole] = useState(""),
    [selectedRecord, setSelectedRecord] = useState({
      id: "",
      modal: false,
    }),
    navigate = useNavigate();

  const fetchRoles = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(`/api/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles(response.data.roles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRoles();
    const intervalId = setInterval(() => {
      fetchRoles();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const handleEdit = (record) => {
    setIsEditing(record);
    setNewRole(record.name);
    setIsCreatingRole(true);
  };

  const deleteRecordAPICall = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true); // Start loading
    try {
      await axiosInstance.delete(`/api/roles/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchRoles();
      toast.success("Record deleted successfully!");
      setSelectedRecord({
        id: "",
        modal: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        // navigate("/");
      } else {
        console.error("Error deleting record:", error);
        toast.error("Error deleting record");
      }
    }
    setIsLoading(false); // End loading
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const updateUserRole = {
      name: newRole,
    };
    try {
      await axiosInstance.put(`/api/roles/${isEditing.id}`, updateUserRole, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Role Updated successfully");
      setIsEditing(false);
      setIsCreatingRole(false);
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error(error.response.data.message);
    }
  };

  const handleSubmitRole = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const token = localStorage.getItem("token");
    try {
      await axiosInstance.post(
        `/api/roles`,
        { name: newRole },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Reset the form fields
      const response = await axiosInstance.get(`/api/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Role created successfully");
      setIsLoading(false);
      setRoles(response.data.roles);
      setNewRole("");
      setIsCreatingRole(false);
      // Optionally, fetch updated role list after creation
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating role:", error);
      toast.error(error.response.data.message);
    }
  };

  const filteredRoles = roles.filter((role) =>
    newRole.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleViewPermisssion = (id) => {
    if (id) {
      onUserSelect(id);
      navigate("/modelListing");
    }
  };

  return (
    <div className=" userLists">
      <CommonHeader
        dashboardName={"Role"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setIsCreatingRole(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <div className="details mt-4">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Role Name</th>
                    <th>Created At</th>
                    <th>Edit Permission by Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRoles.length === 0 ? (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No roles found
                      </td>
                    </tr>
                  ) : (
                    filteredRoles.map((role, index) => (
                      <tr
                        className=""
                        key={role.id}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{role.name}</td>
                        <td>
                          {moment(role.created_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </td>
                        <td>
                          {role?.name === "Super Admin" ? (
                            <>
                              <span
                                data-tooltip-id="View-button"
                                className="fs-6 noView"
                              >
                                <BsEyeSlash />
                              </span>
                              <ReactTooltip
                                id="noView"
                                place="bottom"
                                content="Admin permission cannot view"
                              />
                            </>
                          ) : (
                            <button
                              className="view"
                              data-tooltip-id="View-button"
                              onClick={() => {
                                handleViewPermisssion(role?.id);
                              }}
                              disabled={
                                !hasPermission(user?.permissions, "view-role")
                              }
                            >
                              <FiEye />
                              <ReactTooltip
                                id="View-button"
                                place="bottom"
                                content="View permission"
                              />
                            </button>
                          )}
                        </td>
                        <td>
                          {role.name === "super-admin" ? (
                            <>
                              <span
                                className="noedit "
                                data-tooltip-id="noedit-button"
                              >
                                <TbEditOff />
                              </span>
                              <ReactTooltip
                                id="noedit-button"
                                place="bottom"
                                content="Admin role cannot Edit"
                              />
                            </>
                          ) : (
                            <div className="d-flex ">
                              <Toaster richColors position="top-center" />
                              <>
                                <button
                                  className="edit"
                                  data-tooltip-id="Edit-button"
                                  onClick={() => handleEdit(role)}
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  disabled={
                                    !hasPermission(
                                      user?.permissions,
                                      "update-role"
                                    )
                                  }
                                >
                                  <TbEdit />
                                </button>
                                <ReactTooltip
                                  id="Edit-button"
                                  place="bottom"
                                  content="Edit Role"
                                />
                              </>

                              <Toaster position="top-center" richColors />
                              <button
                                data-tooltip-id="delete-button"
                                className="delete edit"
                                style={{
                                  background: "red",
                                  color: "white",
                                  fontSize: "22px",
                                  padding: "4px 10px",
                                }}
                                onClick={() => {
                                  setSelectedRecord({
                                    id: role.id,
                                    modal: true,
                                  });
                                }}
                                disabled={
                                  !hasPermission(
                                    user?.permissions,
                                    "delete-role"
                                  )
                                }
                              >
                                <MdDelete />
                              </button>
                              <ReactTooltip
                                id="delete-button"
                                place="bottom"
                                content="Delete Client"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal */}
      <DeleteModal
        toggleState={selectedRecord?.modal}
        toggleHandler={() => setSelectedRecord(false)}
        apiCallHandleronClick={() => deleteRecordAPICall(selectedRecord?.id)}
      />
      <CustomModal
        show={isCreatingRole}
        handleClose={() => setIsCreatingRole(false)}
        headerContent={
          <h4 className="create_user_heading">
            {isEditing ? "Update" : "Create"} Role
          </h4>
        }
        bodyContent={
          <Form.Group controlId="formBasicPassword">
            <Form.Control
              type="text"
              name="name"
              className="custom-input-text"
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              placeholder="Role Name"
              autoFocus
              style={{ display: "inline-block" }}
              required
            />
          </Form.Group>
        }
        footerContent={
          <>
            <Toaster richColors position="top-center" />

            <Button
              variant="primary"
              className="creatrUserSubmit"
              onClick={isEditing ? handleSave : handleSubmitRole}
            >
              {isEditing ? "Update" : "Create"} Role
            </Button>

            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={() => setIsCreatingRole(false)}
            >
              Close
            </Button>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default RolesListing;
