import React from 'react';

const DaysFilter = ({ selectedYear, onChange }) => {
    const yearOptions = [2021, 2022, 2023, 2024];

    return (
        <div className="days-filter">
            <label htmlFor="days-select">Select Year: </label>
            <select
                id="year-select"
                value={selectedYear}
                onChange={(e) => onChange(parseInt(e.target.value))}
            >
                {yearOptions.map((year) => (
                    <option key={year} value={year}>
                        {year} Year
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DaysFilter;