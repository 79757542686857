import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { LuEye } from "react-icons/lu";

const AccountManager = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [accountManagers, setAccountManagers] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [clientOptions, setClientOption] = useState([]),
    [record, setRecord] = useState({
      name: "",
      email: "",
      password: "",
      status: "",
      phoneno: "",
      address: "",
      clientId: "",
    }),
    [view, setView] = useState({
      visible: false,
    }),
    [options, setOptions] = useState([]),
    [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRecord({
      name: "",
      email: "",
      status: "",
      phone: "",
      address: "",
    });
    setIsEditing("");
    setView({});
  };
  const navigate = useNavigate();

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/accountManagers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAccountManagers(response.data.accountManagers);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    getDropdowns();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const getDropdowns = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/accountManagers/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const clientDropDown = response?.data?.clients.map((client) => ({
        id: client.id,
        name: client.name,
      }));

      setClientOption(clientDropDown);
      const createOptions = Object.values(response?.data[`status-dropdown`]);

      setOptions([
        {
          id: 0,
          name: createOptions[0],
        },
        {
          id: 1,
          name: createOptions[1],
        },
      ]);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating text");
      } else {
        console.error("Error creating text:", error);
        toast.error("Failed to create text. Please try again later.");
      }
    }
  };

  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(
        `/api/accountManagers/${row.id}/edit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      setShow(true);

      const { name, phone, address, status, user, id } =
        response.data.accountManager;
      const { email } = user;

      setIsEditing(response.data.accountManager);
      const selectedClient = clientOptions.filter((record) => record.id === id);

      setRecord((prevState) => ({
        ...prevState,
        name,
        email,
        address,
        status,
        phoneno: phone,
        clientId: selectedClient[0].id,
      }));
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching record for edit:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/accountManagers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAccountManagers(accountManagers.filter((sound) => sound.id !== id));

      toast.success("Record deleted successfully!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting Record:", error);
        toast.error("Error deleting Record");
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    const { name, email, status, password, phoneno, address } = record;
    const updatedData = {
      name,
      email,
      password,
      phone: phoneno,
      status,
      address,
    };

    try {
      const response = await axiosInstance.put(
        `/api/accountManagers/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();

      if (response.status === 200) {
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error(error.response.data.message || "Please try again");
    }
  };

  const handleCreate = async () => {
    const { name, email, password, phoneno, clientId, address } = record;

    // Validate the form fields
    if (!name || !email || !phoneno || !password || !clientId || !address) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      name,
      email,
      password,
      client_id: clientId,
      status: "1", // Assuming "1" is the default status value
      address,
      phone: phoneno,
    };

    try {
      await axiosInstance.post(`/api/accountManagers`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const handleView = (record) => {
    const {
      name,
      email,
      phone,
      address,
      status,
      client,
      updated_at,
      created_at,
    } = record;
    setView({
      visible: true,
      name,
      email,
      phone,
      address,
      status,
      client,
      updated_at,
      created_at,
    });
    setShow(true);
  };

  const renderTable = (content, id = true) => {
    return (
      <>
        <thead>
          <tr>
            {id && <th>ID</th>}
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Status</th>
            <th>Client</th>
            <th>Created At</th>
            <th>Updated At</th>
            {id && <th className="text-center">Action</th>}
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 ? (
            content?.map((row, i) => (
              <tr key={row.id}>
                {id && <td>{i + 1}</td>}
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>{row.address}</td>
                <td>{row.status}</td>
                <td>{row.client}</td>
                <td>{new Date(row.created_at).toLocaleDateString()}</td>
                <td>{new Date(row.updated_at).toLocaleDateString()}</td>
                {id && (
                  <td className="d-flex align-items-center justify-content-center">
                    <Toaster position="top-center" richColors />
                    <button
                      data-tooltip-id="view-button"
                      className="edit me-2"
                      onClick={() => handleView(row)}
                      style={{
                        padding: "4px 10px",
                        fontSize: "22px",
                      }}
                      disabled={
                        !hasPermission(
                          user?.permissions,
                          "view-account-manager"
                        )
                      }
                    >
                      <LuEye />
                    </button>
                    <ReactTooltip
                      id="view-button"
                      place="bottom"
                      content="View Details"
                    />
                    {/* {hasPermission(user.permissions, "update-freelancer") && ( */}
                    <span
                      className="px-2 edit me-2"
                      style={{ cursor: "pointer" }}
                    >
                      <TbEdit
                        size={24}
                        data-tooltip-id="editTooltip"
                        data-tooltip-content="Edit"
                        onClick={() => handleEdit(row)}
                        disabled={
                          !hasPermission(
                            user?.permissions,
                            "update-account-manager"
                          )
                        }
                      />
                      <ReactTooltip id="editTooltip" />
                    </span>
                    {/* )}
                    {hasPermission(user.permissions, "delete-freelancer") && ( */}
                    <span
                      className="px-2"
                      style={{
                        cursor: "pointer",
                        background: "red",
                        color: "white",
                        fontSize: "16px",
                        padding: "8px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      <MdDelete
                        size={24}
                        data-tooltip-id="deleteTooltip"
                        data-tooltip-content="Delete"
                        onClick={() => handleDelete(row.id)}
                        disabled={
                          !hasPermission(
                            user?.permissions,
                            "delete-account-manager"
                          )
                        }
                      />
                      <ReactTooltip id="deleteTooltip" />
                    </span>
                    {/* )} */}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                No Records found.
              </td>
            </tr>
          )}
        </tbody>
      </>
    );
  };

  const getTableUI = () => {
    return (
      <div className="details mt-4">
        <table className="user-table">{renderTable(accountManagers)}</table>
      </div>
    );
  };

  const getFormUI = () => {
    return view?.visible ? (
      <table class="table table-striped">{renderTable([view], false)}</table>
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Control
            type="text"
            placeholder="Name"
            value={record.name}
            onChange={(e) => setRecord({ ...record, name: e.target.value })}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formText">
          <Form.Control
            type="text"
            placeholder="Email"
            value={record.email}
            onChange={(e) => setRecord({ ...record, email: e.target.value })}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Control
            type="number"
            placeholder="Phone Number"
            value={record.phoneno}
            onChange={(e) => setRecord({ ...record, phoneno: e.target.value })}
          />
        </Form.Group>

        {/* only show in creating */}
        {!isEditing && (
          <Form.Group className="mb-3" controlId="formText">
            <Form.Control
              type="password"
              placeholder="Password"
              value={record.password}
              onChange={(e) =>
                setRecord({ ...record, password: e.target.value })
              }
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="formAddress">
          <Form.Control
            type="text"
            placeholder="Address"
            value={record.address}
            onChange={(e) => setRecord({ ...record, address: e.target.value })}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formClientDropdown">
          <Form.Select
            value={record.clientId}
            onChange={(e) =>
              setRecord({
                ...record,
                clientId: e.target.value,
              })
            }
            style={{
              maxWidth: "310px",
              height: "48px",
              margin: "15px",
              padding: "8px",
            }}
          >
            <option>Client Options</option>
            {clientOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formClientStatus">
          <Form.Select
            value={record.status}
            onChange={(e) =>
              setRecord({
                ...record,
                status: e.target.value,
              })
            }
            style={{
              maxWidth: "310px",
              height: "48px",
              margin: "15px",
              padding: "8px",
            }}
          >
            <option>Status</option>
            {options.map((option, i) => (
              <option key={i} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Account Manager"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {view?.visible ? (
              <h2 className="create_user_heading"> Account Managers List </h2>
            ) : (
              <h2 className="create_user_heading">{`${
                isEditing ? "Update" : "Create"
              } Account Manager`}</h2>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {!view?.visible && (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isEditing ? handleUpdate : handleCreate}
                >
                  {`${isEditing ? "Update" : "Create"} Account Manager`}
                </Button>
              )}
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default AccountManager;
