import React from 'react';

const DaysFilter = ({ selectedDays, onChange }) => {
    const dayOptions = [7, 15, 30, 60, 90];

    return (
        <div className="days-filter">
            <label htmlFor="days-select">Select Days: </label>
            <select
                id="days-select"
                value={selectedDays}
                onChange={(e) => onChange(parseInt(e.target.value))}
            >
                {dayOptions.map((days) => (
                    <option key={days} value={days}>
                        {days} Days
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DaysFilter;