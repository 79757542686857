import React, { useState, useContext } from "react";
import axios from "axios";
import "../login.css";
import { LoginContext } from "../../../App";
import axiosInstance from "../../../refershTokenManage/axiosConfig";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { setCreateUser, setUser } = useContext(LoginContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axiosInstance.post('/api', {
        name,
        email,
        password,
        c_password: confirmPassword,
      });
      setUser({ email, password });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    // <div className="sign-up">
    //   <div className="contain">
    //
    //   </div>
    // </div>
    <div className="main">
      <div className="arrow arrow--top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="270.11"
          height="649.9"
          overflow="visible"
        >
          <style>
            {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
          </style>
          <g className="item-to bounce-1">
            <path
              className="geo-arrow draw-in"
              d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z"
            />
          </g>
          <circle
            className="geo-arrow item-to bounce-2"
            cx="194.65"
            cy="69.54"
            r="7.96"
          />
          <circle
            className="geo-arrow draw-in"
            cx="194.65"
            cy="39.5"
            r="7.96"
          />
          <circle
            className="geo-arrow item-to bounce-3"
            cx="194.65"
            cy="9.46"
            r="7.96"
          />
          <g className="geo-arrow item-to bounce-2">
            <path
              className="st0 draw-in"
              d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552"
            />
          </g>
        </svg>
      </div>
      <div className="arrow arrow--bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.35"
          height="649.9"
          overflow="visible"
        >
          <style>
            {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
          </style>
          <g className="item-to bounce-1">
            <circle
              className="geo-arrow item-to bounce-3"
              cx="15.5"
              cy="580.36"
              r="7.96"
            />
            <circle
              className="geo-arrow draw-in"
              cx="15.5"
              cy="610.4"
              r="7.96"
            />
            <circle
              className="geo-arrow item-to bounce-2"
              cx="15.5"
              cy="640.44"
              r="7.96"
            />
            <g className="item-to bounce-2">
              <path
                className="geo-arrow draw-in"
                d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="main__text-wrapper">
        <form className="form p-3 p-md-5" onSubmit={handleSubmit}>
          <h3 className="mb-2 mb-md-5">Sign Up</h3>
          <h2 className="mb-5 fs-2">Create User To Sign In </h2>
          <div className="inp_name">
            <div className="input_name">
              <input
                type="text"
                placeholder="Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="email_login">
            <div className="input_email">
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="password">
            <div className="input_password">
              <input
                type="password"
                placeholder="Password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="password">
            <div className="input_password">
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </div>
          {errorMessage && (
            <div className="error-message text-danger fw-bold fs-5 text-center mt-3">
              {errorMessage}
            </div>
          )}
          <div className="button">
            <button className="submit" type="submit">
              Sign Up
            </button>
          </div>
          <div className="newuser">
            Already have an account?
            <span className="account ms-2" onClick={() => setCreateUser(false)}>
              Sign In
            </span>
          </div>
        </form>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="dotted-circle"
          width="352"
          height="352"
          overflow="visible"
        >
          <circle
            cx="176"
            cy="176"
            r="174"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="12.921,11.9271"
          />
        </svg>
      </div>
    </div>
  );
};

export default SignUp;
