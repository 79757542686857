import React, { useEffect, useState, useContext } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link, useParams } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { userVideoDetails } from "../../../utils/constants";
import moment from "moment";
import CustomModal from "../common-components/customModal";
import Button from "react-bootstrap/Button";
import { returnBackButton } from "../../../utils/helperfunctions";
import { DateRangePicker } from "rsuite";

const TikTokUserVideosDetails = () => {
  const [pageStart, setPageStart] = useState(1),
    [isLoading, setIsLoading] = useState(false),
    [userNotFound, setUserNotFound] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    { tiktokId } = useParams(),
    [videoDetails, setVideoDetails] = useState({}),
    [viewToggle, setViewToggle] = useState(false),
    [details, setDetails] = useState([]),
    [date, setDate] = useState([]),
    [videoOption, setVideoOptions] = useState([]),
    [selectedVideoId, setSelectedVideoId] = useState("");

  const handleChange = (record) => {
    if (!record || !record?.length) {
      setDate([]);
      return;
    }

    const formatDate = [record[0], record[1]];
    setDate(formatDate);
  };

  useEffect(() => {
    getVideoDetails(currentPage);
  }, [date, selectedVideoId, currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const getVideoDetails = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    let startDate = "";
    let endDate = "";

    if (date?.length) {
      startDate = moment(date[0]).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }

    if (date?.length) {
      endDate = moment(date[1]).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      config.params = {
        page: page,
        video_id: selectedVideoId,
        recorded_at_from: startDate,
        recorded_at_to: endDate,
      };

      const response = await axiosInstance.post(
        `/api/tiktok/user/videos`,
        {
          tiktok_id: tiktokId,
        },
        config
      );

      if (response?.status === 200) {
        const { data, pagination } = response?.data;
        if (!selectedVideoId) {
          setVideoOptions(data.map((v) => v.video_id));
        }
        setVideoDetails(data);
        setTotalPages(pagination.last_page);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
    setIsLoading(false); // End loading
  };

  const handleClose = () => setViewToggle(false);

  const showDetails = (index) => {
    let selectedRow;
    for (let i = 0; i <= videoDetails.length; i++) {
      if (i === index) {
        selectedRow = videoDetails[index];

        setDetails({
          caption: selectedRow?.caption,
          description: selectedRow?.description,
          hashtag: selectedRow?.hashtag?.split(", "),
        });
      }
    }
    setViewToggle(true);
  };

  const handleVideoIdFilter = (event) => {
    setSelectedVideoId(event.target.value);
  };

  const getVideoFilter = () => {
    return (
      <select
        class="form-select"
        aria-label="Default select example"
        onChange={handleVideoIdFilter}
      >
        <option selected value="">
          All Videos
        </option>
        {videoOption?.map((option) => (
          <option value={`${option}`}>{option}</option>
        ))}
      </select>
    );
  };

  return (
    <div className="userLists">
      {userNotFound.status === 403 ? (
        <div
          style={{
            fontSize: "30px",
            position: "fixed",
            top: "50%",
            left: "60vh",
          }}
          className="user-not-found-message container"
        >
          {userNotFound.message}
        </div>
      ) : (
        <>
          <div className="mt-1">
            <div className="userFetchData">
              <div className="userDetails mt-2">
                <div
                  style={{ backgroundColor: "#fff" }}
                  className="d-flex align-content-center align-items-center"
                >
                  {returnBackButton()}

                  <h1 className="fw-bold m-0 p-3 text-center rounded">
                    {videoDetails?.tiktok &&
                      videoDetails?.tiktok?.charAt(0).toUpperCase() +
                        videoDetails?.tiktok?.slice(1)}{" "}
                    Video Details
                  </h1>
                  <span style={{ maxWidth: "200px" }}>{getVideoFilter()}</span>
                  <DateRangePicker
                    style={{ marginLeft: "30px" }}
                    value={date}
                    onChange={handleChange}
                    placeholder="Select Date Range"
                  />
                </div>
                <div className="videoDetails mt-3">
                  <table className="user-table">
                    <thead>
                      <tr>
                        {userVideoDetails.map((heading) => (
                          <th>{heading}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6">Loading...</td>
                        </tr>
                      ) : videoDetails?.length ? (
                        videoDetails?.map((video, index) => (
                          <tr
                            key={video?.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#f2f2f2",
                            }}
                          >
                            <td>
                              <Link to={`/Tiktok-video/${video?.video_id}`}>
                                {video?.video_id}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/Tiktok-video/sound/${video?.sound_id}`}
                              >
                                {video?.sound_id}
                              </Link>
                            </td>
                            <td class="text-center">
                              <a
                                href={video?.video_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                url
                              </a>
                            </td>
                            <td>{video?.views}</td>
                            <td>{video?.likes}</td>
                            <td>{video?.shares}</td>
                            <td>{video?.saved}</td>
                            <td>{video?.comments}</td>
                            <td>
                              {moment(video?.created_at).format(
                                "Do MMMM YY HH:mm"
                              )}
                            </td>
                            <td>
                              <button onClick={() => showDetails(index)}>
                                Details
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No Record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {totalPages > 1 && (
                <div className="pagination">
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageStart={pageStart}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <CustomModal
        show={viewToggle}
        handleClose={handleClose}
        headerContent={<h4 className="create_user_heading mb-2">Details</h4>}
        bodyContent={
          <div style={{ display: "block !important" }}>
            <h6> Caption</h6>
            <p>{details?.caption}</p>
            <h6> Description</h6>
            <p>{details?.description}</p>
            <h6> Hash tags</h6>
            <p>
              {details?.hashtag?.map((tag, i) => (
                <span
                  key={i}
                  class="badge"
                  style={{
                    background: "aqua",
                    marginRight: "10px",
                    fontSize: "15px",
                    marginBottom: "10px",
                  }}
                >
                  {tag}
                </span>
              ))}
            </p>
          </div>
        }
        footerContent={
          <Button
            variant="secondary"
            className="creatrUserClose"
            onClick={handleClose}
          >
            Close
          </Button>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default TikTokUserVideosDetails;
