import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from './DaysFilter';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TopTrendingVideos = () => {
    const [videoData, setVideoData] = useState([]);
    const [selectedDays, setSelectedDays] = useState(30);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTopVideos = async () => {
            const token = localStorage.getItem("token");
            setLoading(true);
            try {
                const response = await axiosInstance.post("/api/dashboard/top-trending-videos", { days: selectedDays }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setVideoData(response.data.data);
                setLoading(false);
            } catch (error) {
                setError("Failed to fetch top trending videos.");
                setLoading(false);
            }
        };

        fetchTopVideos();
    }, [selectedDays]);

    const generateChartData = () => {
        const labels = videoData.map(video => `Video ${video.video_id}`);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Views',
                    data: videoData.map(video => video.views),
                    backgroundColor: '#36A2EB',
                },
                {
                    label: 'Likes',
                    data: videoData.map(video => video.likes),
                    backgroundColor: '#FFCE56',
                },
                {
                    label: 'Comments',
                    data: videoData.map(video => video.comments),
                    backgroundColor: '#4BC0C0',
                },
                {
                    label: 'Shares',
                    data: videoData.map(video => video.shares),
                    backgroundColor: '#FF6384',
                },
                {
                    label: 'Saved',
                    data: videoData.map(video => video.saved),
                    backgroundColor: '#9966FF',
                },
            ],
        };
        return data;
    };

    return (
        <div>
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />

            {loading && (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            )}
            {!loading && !error && videoData.length > 0 ? (
                <Bar
                    data={generateChartData()}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { position: 'top' },
                            title: { display: true, text: `Top 30 Trending TikTok Videos (Last ${selectedDays} Days)` },
                        },
                    }}
                />
            ) : (
                !loading && <p>No trending videos available.</p>
            )}
        </div>
    );
};

export default TopTrendingVideos;
