import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import DaysFilter from './DaysFilter';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const UserStats = () => {
    const [selectedDays, setSelectedDays] = useState(60);
    const [counts, setCounts] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            setLoading(true);
            try {
                const response = await axiosInstance.post("/api/dashboard", { days: selectedDays }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCounts(response.data.counts);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch data");
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedDays]);

    const data = {
        labels: [
            "Super Admins",
            "Admins",
            "Users",
            "Clients",
            "Freelancers",
            "Account Managers",
            "TikTok Accounts",
        ],
        datasets: [
            {
                label: "Counts",
                data: [
                    counts.super_admins || 0,
                    counts.admins || 0,
                    counts.users || 0,
                    counts.clients || 0,
                    counts.freelancers || 0,
                    counts.account_managers || 0,
                    counts.tiktok_accounts || 0,
                ],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                    "#FF9F40",
                    "#FF6384",
                ],
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Users and Tiktok Accounts',
            },
        },
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="col-md-12">
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />
            <Bar data={data} options={options} />
        </div>
    );
};

export default UserStats;