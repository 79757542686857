import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../login.css";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";

import CommonLoader from "../../../loader/CommonLoader";

const SignIn = () => {
  const [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    [loading, setLoading] = useState(false),
    [loginError, setLoginError] = useState(false),
    { setCreateUser, setUser } = useContext(LoginContext),
    navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/api/login`, {
        email,
        password,
      });
      const token = response.data.authorization.token;
      const refreshToken = response.data.authorization.refresh_token;
      const userData = response.data.user;
      const permissions = userData.roles.flatMap((role) =>
        role.permissions.map((permission) => permission.name)
      );

      localStorage.setItem("userName", userData.name);
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("userRole", userData.roles[0].name);
      localStorage.setItem("permissions", JSON.stringify(permissions));

      setUser({ email, isLoggedIn: true, permissions });
      setEmail("");
      setPassword("");
      setLoginError(false);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUser({
        isLoggedIn: true,
        permissions: JSON.parse(localStorage.getItem("permissions")),
      });
    }
  }, [setUser]);

  return (
    <div className="main">
      <div className="arrow arrow--top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="270.11"
          height="649.9"
          overflow="visible"
        >
          <style>
            {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
          </style>
          <g className="item-to bounce-1">
            <path
              className="geo-arrow draw-in"
              d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z"
            />
          </g>
          <circle
            className="geo-arrow item-to bounce-2"
            cx="194.65"
            cy="69.54"
            r="7.96"
          />
          <circle
            className="geo-arrow draw-in"
            cx="194.65"
            cy="39.5"
            r="7.96"
          />
          <circle
            className="geo-arrow item-to bounce-3"
            cx="194.65"
            cy="9.46"
            r="7.96"
          />
          <g className="geo-arrow item-to bounce-2">
            <path
              className="st0 draw-in"
              d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552"
            />
          </g>
        </svg>
      </div>
      <div className="arrow arrow--bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.35"
          height="649.9"
          overflow="visible"
        >
          <style>
            {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
          </style>
          <g className="item-to bounce-1">
            <circle
              className="geo-arrow item-to bounce-3"
              cx="15.5"
              cy="580.36"
              r="7.96"
            />
            <circle
              className="geo-arrow draw-in"
              cx="15.5"
              cy="610.4"
              r="7.96"
            />
            <circle
              className="geo-arrow item-to bounce-2"
              cx="15.5"
              cy="640.44"
              r="7.96"
            />
            <g className="item-to bounce-2">
              <path
                className="geo-arrow draw-in"
                d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="main__text-wrapper">
        <div className="form p-3 p-md-5">
          <h3 className="mb-2 mb-md-5 fs-1">Sign In</h3>

          <h4 className="mb-5 fs-2">Login To Your Account</h4>
          <div className="email_login">
            <div className="input_email">
              <input
                type="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="password">
            <div className="input_password">
              <input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          {loading && (
            <div className="loader-overlay">
              <CommonLoader />
            </div>
          )}
          {loginError && (
            <div className="error-message text-danger fw-bold fs-5 text-center mt-3">
              Incorrect email or password
            </div>
          )}
          <div className="button">
            <button className="submit" onClick={handleLogin} disabled={loading}>
              Sign In
            </button>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="dotted-circle"
          width="352"
          height="352"
          overflow="visible"
        >
          <circle
            cx="176"
            cy="176"
            r="174"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="12.921,11.9271"
          />
        </svg>
      </div>
    </div>
  );
};

export default SignIn;
