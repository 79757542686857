import React from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import TikTokStats from "../pages/component/TikTokStats";
import UserCount from "../pages/component/UserCount";
import TopTrendingVideos from "./component/TopTrendingVideos";
import TikTokFollowerGrowth from "./component/TikTokFollowerGrowth";
import TikTokEngagementMetrics from "./component/TikTokEngagementMetrics";
import TikTokHashtagPerformance from "./component/TikTokHashtagPerformance";
import TotalVideosMetrix from "./component/TotalVideosMetrix";
import EffectivenessMetrix from "./component/EffectivenessMetrix";
import ViewsAvgLineMetrix from "./component/ViewsAvgLineMetrix";
import InteractionsAvgLineMetrix from "./component/InteractionsAvgLineMetrix";

const DashboardIndex = () => {
    const role = localStorage.getItem("userRole");
    const hasRole = role === 'super-admin' || role === 'admin' || role === 'client' || role === 'freelancer' || role === 'account-manager';
    const hasAdminRole = role === 'super-admin' || role === 'admin';
    const hasClientRole = role === 'client';
    return (
        <div className="container">
            <CommonHeader
                dashboardName={"Dasboard"}
            />
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            {hasAdminRole && <UserCount />}
                        </div>
                    </div>
                    {hasAdminRole && <hr className="mt-4" />}
                    <div className="row">
                        <div className="col-md-12">
                            {hasClientRole && <TikTokStats />}
                        </div>
                    </div>
                    {hasClientRole && <hr className="mt-4" />}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {hasRole && <TopTrendingVideos />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <TikTokFollowerGrowth />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <TikTokEngagementMetrics />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <TikTokHashtagPerformance />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <TotalVideosMetrix />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <EffectivenessMetrix />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <ViewsAvgLineMetrix />}
                </div>
                {hasRole && <hr className="mt-4" />}
                <div className="col-md-12">
                    {hasRole && <InteractionsAvgLineMetrix />}
                </div>
            </div>
        </div>
    );
};

export default DashboardIndex;
