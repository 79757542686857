import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from './DaysFilter';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TikTokHashtagPerformance = () => {
    const [selectedDays, setSelectedDays] = useState(30);
    const [hashtagPerformanceData, setHashtagPerformanceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHashtagPerformance = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");
            try {
                const response = await axiosInstance.post("/api/dashboard/hashtag-performance",
                    {
                        days: selectedDays,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                setHashtagPerformanceData(response.data.data);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch TikTok hashtag performance data");
                setLoading(false);
            }
        };

        fetchHashtagPerformance();
    }, [selectedDays]);

    const abbreviateLabel = (label) => {
        const maxLength = 10; // Set a maximum length for the label
        return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
    };

    const generateChartData = () => {
        const labels = hashtagPerformanceData.map(item => abbreviateLabel(item.hashtag));
        const data = {
            labels,
            datasets: [
                {
                    label: 'Total Views',
                    data: hashtagPerformanceData.map(item => item.total_views),
                    backgroundColor: '#36A2EB',
                },
            ],
        };
        return data;
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'TikTok Hashtag Performance' },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        // Show full label on hover
                        const originalLabel = hashtagPerformanceData[tooltipItem.dataIndex].hashtag;
                        const value = tooltipItem.raw;
                        return `${originalLabel}: ${value}`;
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    maxRotation: 45, // Rotate labels up to 45 degrees
                    minRotation: 30, // Rotate labels to at least 30 degrees
                    autoSkip: true, // Automatically skip labels if they overlap
                    maxTicksLimit: 10, // Limit the number of ticks
                },
            },
        },
    };

    return (
        <div className="hashtag-performance-container">
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />
            {loading ? (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            ) : error ? (
                <div className="error-overlay">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="chart-wrapper">
                    {hashtagPerformanceData.length > 0 ? (
                        <Bar
                            data={generateChartData()}
                            options={chartOptions}
                        />
                    ) : (
                        <p>No hashtag performance data available.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default TikTokHashtagPerformance;