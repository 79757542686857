import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from './DaysFilter';
import { debounce } from "lodash"; // Lodash for debouncing
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TikTokEngagementMetrics = () => {
    const [selectedDays, setSelectedDays] = useState(30);
    const [engagementData, setEngagementData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const debouncedFetchEngagementMetrics = debounce(async (days) => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
            const response = await axiosInstance.post("/api/dashboard/engagement-metrics",
                { days },
                {
                    headers: { Authorization: `Bearer ${token}` },
                });
            setEngagementData(response.data.data);
            setLoading(false);
        } catch (err) {
            setError(err.response?.data?.message || "Failed to fetch TikTok engagement metrics");
            setLoading(false);
        }
    }, 300);

    useEffect(() => {
        debouncedFetchEngagementMetrics(selectedDays);
    }, [selectedDays]);

    const generateChartData = () => {
        const labels = engagementData.map(item => item.date);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Total Views',
                    data: engagementData.map(item => item.total_views),
                    borderColor: '#36A2EB',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total Likes',
                    data: engagementData.map(item => item.total_likes),
                    borderColor: '#FF6384',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total Comments',
                    data: engagementData.map(item => item.total_comments),
                    borderColor: '#FFCE56',
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total Shares',
                    data: engagementData.map(item => item.total_shares),
                    borderColor: '#4BC0C0',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total Saved',
                    data: engagementData.map(item => item.total_saved),
                    borderColor: '#9966FF',
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    fill: true,
                },
            ],
        };
        return data;
    };

    return (
        <div className="engagement-metrics-container">
            <DaysFilter selectedDays={selectedDays} onChange={setSelectedDays} />
            {loading ? (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            ) : error ? (
                <div className="error-overlay">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="chart-wrapper" style={{ height: "538px" }}>
                    {engagementData.length > 0 ? (
                        <Line
                            data={generateChartData()}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: { position: 'top' },
                                    title: { display: true, text: 'TikTok Engagement Metrics' },
                                },
                            }}
                        />
                    ) : (
                        <p>No engagement metrics available.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default TikTokEngagementMetrics;
