import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonLoader from "../../../loader/CommonLoader";
import MetrixCards from "./userListingMetrix/MetrixCards";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { SlUserFollowing } from "react-icons/sl";
import { GiShadowFollower } from "react-icons/gi";
import { MdFavorite } from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { videoDetails as videoDetailsConstant } from "../../../utils/constants";
import moment from "moment";
import CustomModal from "../common-components/customModal";
import Button from "react-bootstrap/Button";

const TikTokVideoDetails = () => {
  const [pageStart, setPageStart] = useState(1),
    [isLoading, setIsLoading] = useState(false),
    [userNotFound, setUserNotFound] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    { videoId } = useParams(),
    [videoDetails, setVideoDetails] = useState([]),
    [videoStats, setVideoStats] = useState({}),
    [viewToggle, setViewToggle] = useState(false),
    [hashtags, setHashtags] = useState([]);

  useEffect(() => {
    getVideoDetails();
  }, []);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const getVideoDetails = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axiosInstance.post(
        `/api/tiktok/user/video`,
        {
          video_id: videoId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200) {
        setVideoDetails(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
    setIsLoading(false); // End loading
  };
  const handleClose = () => setViewToggle(false);

  return (
    <div className="userLists">
      {userNotFound.status === 403 ? (
        <div
          style={{
            fontSize: "30px",
            position: "fixed",
            top: "50%",
            left: "60vh",
          }}
          className="user-not-found-message container"
        >
          {userNotFound.message}
        </div>
      ) : (
        <>
          <div className="mt-1">
            <div className="userFetchData">
              <div className="userDetails mt-2">
                <div
                  style={{ backgroundColor: "#fff" }}
                  className="d-flex rounded-2 nav align-content-center"
                >
                  <h1 className="fw-bold m-0 p-3 text-center rounded">
                    Video # {videoId} Details
                  </h1>
                </div>
                <div className="videoDetails mt-3">
                  <table className="user-table">
                    <thead>
                      <tr>
                        {videoDetailsConstant?.map((heading) => (
                          <th>{heading}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6">Loading...</td>
                        </tr>
                      ) : videoDetails?.length ? (
                        videoDetails?.map((video, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#f2f2f2",
                            }}
                          >
                            <td>{video?.views}</td>
                            <td>{video?.likes}</td>
                            <td>{video?.comments}</td>
                            <td>{video?.shares}</td>
                            <td>{video?.saved}</td>
                            <td>
                              {moment(video?.created_at).format(
                                "MMMM D, YYYY, h:mm a"
                              )}
                            </td>
                            <td>
                              {moment(video?.updated_at).format(
                                "MMMM D, YYYY, h:mm a"
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No Record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {totalPages > 1 && (
                <div className="pagination">
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageStart={pageStart}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <CustomModal
        show={viewToggle}
        handleClose={handleClose}
        headerContent={<h4 className="create_user_heading mb-2">Details</h4>}
        bodyContent={
          <>
            <h6> Hash tags</h6>
            <p>
              {hashtags?.map((tag, i) => (
                <span
                  key={i}
                  class="badge"
                  style={{
                    background: "aqua",
                    marginRight: "10px",
                    fontSize: "15px",
                    marginBottom: "10px",
                  }}
                >
                  {tag}
                </span>
              ))}
            </p>
          </>
        }
        footerContent={
          <Button
            variant="secondary"
            className="creatrUserClose"
            onClick={handleClose}
          >
            Close
          </Button>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default TikTokVideoDetails;
