import React, { useState, useContext } from "react";
import "./Header.css";
import { FaHome } from "react-icons/fa";
import { TfiMenuAlt } from "react-icons/tfi";
import { TbMenuDeep } from "react-icons/tb";
import { IoSettingsSharp } from "react-icons/io5";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { LoginContext } from "../../../../App";
import CommonLoader from "../../../../loader/CommonLoader";
import { Toaster, toast } from "sonner";

const Header = () => {
  const { setUser } = useContext(LoginContext);
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");

  const [showResetModal, setShowResetModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState(""); // State for validation error message

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.post(
        `/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      localStorage.removeItem("token");
      localStorage.clear();
      setUser({});
      window.location.href = "/";
    } catch (error) {
      console.error("Logout error:", error);
      localStorage.removeItem("token");
      localStorage.clear();
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    // Validation check for empty fields
    if (!currentPassword || !newPassword) {
      setValidationError("Please fill out both fields.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post(
        `/api/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Password updated successfully");
      setShowResetModal(false);
      setNewPassword("");
      setCurrentPassword("");
      setValidationError("");
    } catch (error) {
      console.error("Password change error:", error);
    }
  };

  return (
    <div>
      <header>
        <div className="headerContainer d-flex align-items-center justify-content-between p-2">
          <div className="navLink">
            <ul className="d-flex nav-list m-0 list-unstyled ">
              <li className="d-flex align-items-center">
                <FaHome className="me-1 fs-5" /> Home
              </li>
              <li className="d-flex align-items-center">
                <TfiMenuAlt className="me-1 fs-5" /> Contents
              </li>
              <li className="d-flex align-items-center">
                {" "}
                <TbMenuDeep className="me-1 fs-5" /> Categories
              </li>
              <li className="d-flex align-items-center">
                {" "}
                <IoSettingsSharp className="me-1 fs-5" /> Setting
              </li>
            </ul>
          </div>
          <div className="userLoginDetails">
            <div>
              <Dropdown className="me-4 fs-4  fw-bold p-1 rounded brand">
                <Dropdown.Toggle
                  variant="success"
                  className="fw-bold"
                  id="dropdown-basic"
                >
                  {userName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Role : {userRole}</Dropdown.Item>
                  <Toaster richColors position="top-center" />
                  <Dropdown.Item onClick={() => setShowResetModal(true)}>
                    Reset password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Modal
                show={showResetModal}
                onHide={() => {
                  setShowResetModal(false);
                  setValidationError(""); // Clear validation error when modal is closed
                }}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="formCurrentPassword">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter current password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Group>
                  {validationError && (
                    <p className="text-danger">{validationError}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowResetModal(false);
                      setValidationError(""); // Clear validation error when modal is closed
                    }}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleResetPassword}>
                    Save Password
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
